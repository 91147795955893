<template lang="pug">
    section#banner
        // SvgIcon(data="@svgs/home-lines.svg", original).lines
        .img
            img(:src="require('@images/home/lines.png')", original).lines
        .wrapper
            .svgs
                SvgIcon(data="@svgs/icon_01.svg", original)._svg01
                SvgIcon(data="@svgs/icon_02.svg", original)._svg02
                SvgIcon(data="@svgs/icon_03.svg", original)._svg03
            .text
                h1
                    |Pr
                    span._1100 a
                    |z
                    span._1100 e
                    |r,
                    br
                    |s
                    span._1100 o
                    |m
                    span._1100 o
                    |s a&nbsp
                    SvgIcon(data="@svgs/mind-text.svg", original).mind
                p.first
                    |Somos um estúdio de&nbsp
                    span.double
                        span.floating estimulos neurais
                        span.riscado Design Estratégico.
                p.second
                    |Chegamos para&nbsp
                    span.double
                        span.floating conectar sinapses
                        span.riscado resolver problemas complexos,
                    span.br
                        br
                    |transformar negócios e ajudar você e seus clientes
                    span.br
                        br
                    |a anteciparem tendências e comportamentos.
            SvgIcon(data="@svgs/m.svg", original).m


</template>

<script>
export default {
    name: "section-banner",
}
</script>

<style lang="stylus" scoped src="./Banner.styl"></style>
