<template lang="pug">
    section#mindMethod
        .wrapper
            .texto
                img(:src="require('@images/home/color_01.jpg')")
                h3
                    |N<span class="acento">O</span>S IMERGIMOS
                    br
                    |TOTALMENTE
                    br
                    |NO SEU UNIVERSO
                p
                    |Com uma atmosfera estimulante, potencializamos<br>
                    |as sinapses utilizando uma metodologia<br>
                    |criativa e instigante:
                    strong o Mindmethod
                    //- |Nós mergulhamos em cada partícula sináptica do seu negócio e de seus clientes, com uma visão que permeia do macro ao micro, identificando riscos e oportunidades.
                img(:src="require('@images/home/color_02.jpg')")
            #circles
                img.star(:src="require('@images/home/mindmethod-star.png')")
                img.circle(:src="require('@images/home/mindmethod-circle.png')")


</template>

<script>
import { isMobile } from '@lib/isMobile'

export default {
    name: "section-mind-method",
     data() {
        return {
            scenes: []
        }
    },
    mounted() {
        // if(!isMobile()){
            this.initScenes()
            setTimeout(() => {
                this.$scrollmagic.addScene(this.scenes)
            }, 500)
        // }
    },
    methods: {
        initScenes() {
            this.scenesMethod()
        },
        scenesMethod() {
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#mindMethod',
				triggerHook: 0.5,
                reverse: false
			}).setTween("#mindMethod h3", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#mindMethod',
				triggerHook: 0.5,
                reverse: false
			}).setTween("#mindMethod p", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
                triggerElement: "#mindMethod",
                triggerHook: 0.5,
                reverse: true,
                duration: 1500
            }).setTween("#mindMethod #circles .star", {
				css: {
					transform: "translateY(-50%)"
				}
			}));
            this.scenes.push(this.$scrollmagic.scene({
                triggerElement: "#mindMethod",
                triggerHook: 0.5,
                reverse: true,
                duration: 1500
            }).setTween("#mindMethod #circles .circle", {
				css: {
					transform: "translateY(30%)"
				}
			}));
        }
    }
}
</script>

<style lang="stylus" scoped src="./MindMethod.styl"></style>
