<template lang="pug">
    section#solucoes
        SvgIcon(data="@svgs/equipe/explosion.svg", original).explosion
        .wrapper
            h2
                | O mundo está sempre
                br
                | mudando. As soluções
                br
                | para ele, também
            p
                |Nosso propósito é ajudar pessoas e empresas<br>
                |a transformarem seus negócios, superarem<br>
                |seus desafios e mapearem futuras<br>
                |possibilidades de inovação.
            .svgs
                SvgIcon(data="@svgs/icon_01.svg", original)._svg01
                SvgIcon(data="@svgs/icon_02.svg", original)._svg02
                SvgIcon(data="@svgs/icon_03.svg", original)._svg03
</template>

<script>
import { isMobile } from '@lib/isMobile'

export default {
    name: "section-solucoes",
    data() {
        return {
            scenes: []
        }
    },
    mounted() {
        // if(!isMobile()){
            this.initScenes()
            setTimeout(() => {
                this.$scrollmagic.addScene(this.scenes)
            }, 500)
        // }
    },
    methods: {
        initScenes() {
            this.scenesSolucoes()
        },
        scenesSolucoes() {
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#solucoes',
				triggerHook: 0.5,
                reverse: false
			}).setTween("#solucoes h2", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#solucoes',
				triggerHook: 0.4,
                reverse: false
			}).setTween("#solucoes p", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))


            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#solucoes',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#solucoes .svgs ._svg01", {
				css: {
					opacity: '1',
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#solucoes',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#solucoes .svgs ._svg02", {
				css: {
					opacity: '1',
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#solucoes',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#solucoes .svgs ._svg03", {
				css: {
					opacity: '1',
				}
			}))
        }
    }
}
</script>

<style lang="stylus" scoped src="./Solucoes.styl"></style>
