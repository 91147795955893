<template lang="pug">
    section#provocadores
        SvgIcon(data="@svgs/neuronio.svg").neuronio
        .wrapper
            .texto
                h2
                    |s
                    span._1900 o
                    |m
                    span._1900 o
                    |s
                    br
                    | Pr
                    span._1100 o
                    | v
                    span._1100 o
                    | c
                    span._1100 a
                    | d
                    span._1100 o
                    | r
                    span._1100 e
                    | s
                p
                    |Revolucionamos a forma de fazer e remodelamos
                    |o modo de desenvolver os projetos.
                    br
                    br
                    |Isso, por meio de uma experiência imersiva impactante
                    |que agrega inteligência coletiva, gera conexões autênticas
                    |e incita grandes questionamentos.
            ul.icons
                li
                    SvgIcon(data="@svgs/icon_01.svg", original)._01
                li
                    SvgIcon(data="@svgs/icon_02.svg", original)._02
                li
                    SvgIcon(data="@svgs/icon_03.svg", original)._03
</template>

<script>
import { isMobile } from '@lib/isMobile'

export default {
    name: "section-provocadores",
    data() {
        return {
            scenes: []
        }
    },
    mounted() {
        // if(!isMobile()){
        this.initScenes()
            setTimeout(() => {
                this.$scrollmagic.addScene(this.scenes)
            }, 500)
        // }
    },
    methods: {
        initScenes() {
            this.scenesProvocadores()
        },
        scenesProvocadores() {
            //First
			this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#provocadores',
				triggerHook: 0.2,
                reverse: false
			}).setTween("#provocadores .texto h2", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#provocadores',
				triggerHook: 0.2,
                reverse: false
			}).setTween("#provocadores .texto p", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#provocadores',
				triggerHook: 0.4,
                reverse: false
			}).setTween("#provocadores .icons ._01", {
				css: {
					opacity: '1',
				}
			}))

            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#provocadores',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#provocadores .icons ._02", {
				css: {
					opacity: '1',
				}
			}))

            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#provocadores',
				triggerHook: 0.2,
                reverse: false
			}).setTween("#provocadores .icons ._03", {
				css: {
					opacity: '1',
				}
			}))
        }
    }
}
</script>

<style lang="stylus" scoped src="./Provocadores.styl"></style>
