<template lang="pug">
    section#justMind
        .wrapper
            .left
                .svgs
                    SvgIcon(data="@svgs/icon_01.svg", original)._svg01
                    SvgIcon(data="@svgs/icon_02.svg", original)._svg02
                    SvgIcon(data="@svgs/icon_03.svg", original)._svg03
                p
                    span Somos um hub de Inteligência
                    span
                        |Um lugar onde nossas mentes
                        br
                        |se conectam com as suas mentes
                    span E a solução aparece
                    span Nossas sinapses entram em sintonia
                p.padding
                    span Para fazer sua empresa se destacar
                    span Seu projeto acontecer
                    span
                        |É o seu inconformismo com
                        br
                        |a inteligência Mind
                    span
                        |Juntos, entregando resultados
                        br
                        |e rompendo padrões
            .right
                img(:src="require('@images/home/lines_orange.png')").lines
                h3
                    span.line01
                        |D
                        span._1100 o
                        |n't
                    br
                    span.line02
                        |th
                        span._1100 i
                        |nk
                    br
                    span.line03
                        |j
                        span._1900 u
                        |st
                    br
                    span.line04
                        |m
                        span._1900 i
                        |nd
</template>

<script>
import { isMobile } from '@lib/isMobile'

export default {
    name: "section-just-mind",
    data() {
        return {
            scenes: []
        }
    },
    mounted() {
        // if(!isMobile()){
            this.initScenes()
            setTimeout(() => {
                this.$scrollmagic.addScene(this.scenes)
            }, 500)
        // }
    },
    methods: {
        initScenes() {
            this.scenesJust()
        },
        scenesJust() {
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.5,
                reverse: false
			}).setTween("#justMind .left .svgs ._svg01", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.4,
                reverse: false
			}).setTween("#justMind .left .svgs ._svg02", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#justMind .left .svgs ._svg03", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))

            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.4,
                reverse: false
			}).setTween("#justMind .left p", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))


            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.6,
                reverse: false
			}).setTween("#justMind .right h3 .line01", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.5,
                reverse: false
			}).setTween("#justMind .right h3 .line02", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.4,
                reverse: false
			}).setTween("#justMind .right h3 .line03", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
            this.scenes.push(this.$scrollmagic.scene({
				triggerElement: '#justMind',
				triggerHook: 0.3,
                reverse: false
			}).setTween("#justMind .right h3 .line04", {
				css: {
					opacity: '1',
					transform: "translateY(0)"
				}
			}))
        }
    }
}
</script>

<style lang="stylus" scoped src="./JustMind.styl"></style>
