<template lang="pug">
	main#home
		Header
		Banner
		Provocadores
		//- Faixas
		JustMind
		MindMethod
		//- Conexoes
		Solucoes
</template>

<script>
import Header from '@components/Header/Header'
import Banner from '@sections/Home/Banner/Banner'
import Provocadores from '@sections/Home/Provocadores/Provocadores'
// import Faixas from '@sections/Home/Faixas/Faixas'
import JustMind from '@sections/Home/JustMind/JustMind'
import MindMethod from '@sections/Home/MindMethod/MindMethod'
// import Conexoes from '@sections/Home/Conexoes/Conexoes'
import Solucoes from '@sections/Home/Solucoes/Solucoes'

export default {
	name: 'view-home',
	components: {
		Header,
		Banner,
		Provocadores,
		// Faixas,
		JustMind,
		MindMethod,
		// Conexoes,
		Solucoes,
	},
	metaInfo() {
		return {
			title: 'Mind Service',
			meta: [
				{ name: 'description', content: 'Conectando inteligências' },
			],
		}
	},
}
</script>

<style lang="stylus" scoped src="./Home.styl"></style>
